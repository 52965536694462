<template>
  <v-dialog v-model="isShown" max-width="600">
    <v-card data-cy="deactivateAutoDeletionDialog">
      <v-card-title>
        {{ $t('business.auto_deletion.deactivation_dialog.title') }}
      </v-card-title>
      <v-card-text>
        <p class="pb-5">
          {{ $t('business.auto_deletion.deactivation_dialog.subtitle') }}
        </p>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn size="x-large" variant="outlined" color="info" @click="isShown = false">
          {{ $t('global.no_thanks') }}
        </v-btn>
        <v-spacer />
        <v-btn
          data-cy="deactivateAutoDeletionButton"
          color="error"
          size="x-large"
          variant="elevated"
          @click="onDeactivate"
        >
          {{ $t('business.auto_deletion.deactivate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isShown: {
      get(): boolean {
        return this.modelValue
      },
      set(newValue: boolean): void {
        this.$emit('update:modelValue', newValue)
      },
    },
  },
  methods: {
    onDeactivate() {
      this.$emit('deactivate')
      this.isShown = false
    },
  },
})
</script>
